import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { includes } from 'lodash-es'
import FirstMenu from '../FirstMenu'
import SecondMenu from '../SecondMenu'
import Footer from '../Footer'
import PageHelmet from '../PageHelmet'
import HelloBar from '../HelloBar'
import styles from './Layout.module.scss'
import 'bootstrap/dist/css/bootstrap.css'
import './layout.css'

export default function Layout({
  children,
  location,
  pageContext: { layout },
}) {
  const aptitudeTest = useSelector((state) => state.aptitudeTest)
  let { scrollableLayout, fullwidthLayout, exclude } = false
  if (layout) {
    scrollableLayout = layout.scrollableLayout
    fullwidthLayout = layout.fullwidthLayout
    if (layout.type && layout.type.exclude) {
      exclude = true
    }
  }
  return exclude ? (
    children
  ) : (
    <>
      <PageHelmet />
      <FirstMenu location={location} scrollableLayout={scrollableLayout} />
      <div
        className={styles.secondMenuWrap}
        // Conditional styling below - for the articles pages only
        style={{
          position: scrollableLayout && 'relative',
          top: scrollableLayout && '0',
        }}
      >
        {/* SecondMenu and HelloBar is available only for aptitude tests and articles with
      'aptitude-test' in the URL */}
        {location.pathname.match('aptitude-test') &&
          !includes(aptitudeTest, '') && (
            <>
              <SecondMenu location={location} />
              <HelloBar {...{ location, scrollableLayout }} />
            </>
          )}
      </div>
      <div className={classNames({ container: !fullwidthLayout })}>
        {children}
      </div>
      <Footer />
    </>
  )
}
