module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T33HH9","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"JIFTJjf_NRB_XaHDaBW9OA","gtmPreview":"env-2","routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WikiJob","short_name":"WikiJob","start_url":"/","background_color":"#fff","theme_color":"#ed863e","theme_color_in_head":false,"display":"standalone","icon":"src/images/favicon.png","cache_busting_mode":"query","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fdbed05d7c6a136b3077d9e55db517d0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/buildhome/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
