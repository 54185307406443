import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import favicon16x16 from 'src/images/favicon/favicon-16x16.png'
import favicon32x32 from 'src/images/favicon/favicon-32x32.png'
import appleTouchIcon from 'src/images/favicon/apple-touch-icon.png'

const PageHelmet = ({
  title,
  description,
  keywords,
  image,
  url,
  lang,
  children,
}) => {
  let imagePath =
    'https://www.datocms-assets.com/7756/1560847067-41auzq9ntel-us230.jpg'
  if (image) {
    imagePath = image
  }

  return (
    <Helmet>
      <html lang={lang ? lang : 'en'} />
      {title && [
        <title
          {...{
            key: 'title',
            itemProp: 'name',
          }}
        >
          {title}
        </title>,
        <meta
          {...{
            key: 'metaTitle',
            name: 'title',
            content: title,
          }}
        />,
        <meta
          {...{
            key: 'ogTitle',
            property: 'og:title',
            content: title,
          }}
        />,
        <meta
          {...{
            key: 'twitterTitle',
            name: 'twitter:title',
            content: title,
          }}
        />,
        <meta
          {...{
            key: 'ogImage',
            property: 'og:image',
            content: imagePath,
          }}
        />,
        <meta
          {...{
            key: 'twitterImage',
            name: 'twitter:image',
            content: imagePath,
          }}
        />,
        <meta
          {...{
            key: 'twitterCard',
            name: 'twitter:card',
            content: 'summary_large_image',
          }}
        />,
        <meta
          {...{
            key: 'image',
            itemProp: 'image',
            content: imagePath,
          }}
        />,
        <meta
          {...{
            key: 'name',
            itemProp: 'name',
            content: title,
          }}
        />,
      ]}
      <meta property="og:type" content="article" />
      {url && <meta property="og:url" content={url} />}
      {url && <link rel="canonical" href={url} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <link
        {...{
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: appleTouchIcon,
        }}
      />
      <link
        {...{
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: favicon32x32,
        }}
      />
      <link
        {...{
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: favicon16x16,
        }}
      />
      <link rel="preconnect" href="https://exchange.mediavine.com" />
      <link rel="preconnect" href="https://mc.yandex.ru" />
      <link rel="preconnect" href="https://i.skimresources.com" />
      <link rel="preconnect" href="https://r.skimresources.com" />
      <link rel="preconnect" href="https://www.youtube.com" />
      <link rel="preconnect" href="https://graph.facebook.com" />
      <link rel="preconnect" href="https://scripts.mediavine.com" />
      <link rel="preconnect" href="https://s.skimresources.com" />
      <link rel="preconnect" href="https://www.clarity.ms" />
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://t.skimresources.com" />
      <link rel="preconnect" href="https://p.skimresources.com" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://tests.wikijob.co.uk" />
      <link rel="preconnect" href="https://l.wikijob.co.uk" />
      <link rel="preconnect" href="https://linkly.wikijob.co.uk" />
      <meta
        {...{
          key: 'description',
          name: 'description',
          content: description ? description : 'WikiJob',
        }}
      />
      <meta
        {...{
          key: 'ogDescription',
          property: 'og:description',
          content: description ? description : 'WikiJob',
        }}
      />
      <meta
        {...{
          key: 'twitterDescription',
          name: 'twitter:description',
          content: description ? description : 'WikiJob',
        }}
      />

      {children && children}
    </Helmet>
  )
}

PageHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
}

export default PageHelmet
