import React from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'

import rootReducer from './reducers'

const store = configureStore({
  reducer: rootReducer,
})

export default ({ element }) => <Provider store={store}>{element}</Provider>
