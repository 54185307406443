import { createSlice } from '@reduxjs/toolkit'

const aptitudeTestSlice = createSlice({
  name: 'aptitudeTest',
  initialState: {
    helloBarUrl: '',
    helloBarButtonText: '',
    helloBarText: '',
  },
  reducers: {
    setHelloBarContent: (state, action) => {
      state.helloBarUrl = action.payload.url
      state.helloBarButtonText = action.payload.buttonText
      state.helloBarText = action.payload.text
    },
  },
})

export const { setHelloBarContent } = aptitudeTestSlice.actions

export default aptitudeTestSlice.reducer
