import React from 'react'
import './style.css'
import {useLocation} from "@reach/router";
const SubscribeToNewsletter = () => {

  const {pathname} = useLocation();

  return <div className="subscribe-to-newsletter">
    <p>Sign Up for Our Newsletter</p>

    <div className="subscribe-form">
      {/* This could be replaced with needed external form (from iframe) */}

      <form name="subscribe" data-netlify="true" netlify-honeypot="bot-field">
        <p className="d-none">
          <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
        </p>
        <input type="hidden" name="placement" value="footer" />
        <input type="hidden" name="path" value={pathname} />
        <input type="hidden" name="form-name" value="subscribe" />
        <input required type="email" name="email" placeholder="Your Email" />
        <button type="submit">Subscribe</button>
      </form>
    </div>
  </div>
}

export default SubscribeToNewsletter
