function articleUrl(article) {
  if (article.shortUrl) {
    return '/' + article.slug
  } else {
    return (
      '/content/' +
      article.category.category.slug +
      '/' +
      article.category.slug +
      '/' +
      article.slug
    )
  }
}

module.exports = (article) => {
  return articleUrl(article)
}
