import React, { useState } from 'react'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import { Search } from 'react-feather'
import classNames from 'classnames'
import WikiJobDropdownMenu from 'src/components/WikiJobDropdownMenu'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import styles from './FirstMenu.module.scss'

const FirstMenu = ({ location, scrollableLayout }) => {
  const [search, updateSearch] = useState('')
  const path = location.pathname
  const [open, updateOpen] = useState(false)
  const onPressEnterSearch = (event) => {
    const e = event.nativeEvent
    if (e.code === 'Enter' && typeof window !== 'undefined') {
      navigate(`/search?q=${search}`)
      updateSearch('')
    }
  }

  const data = useStaticQuery(graphql`
    query allFirstMenus {
      menuLinks: allDatoCmsFirstMenu(limit: 100) {
        edges {
          node {
            title
            position
            menuItems {
              id
              url
              text
            }
            urlMatch
            navbarLink
          }
        }
      }
    }
  `)

  return (
    <Navbar
      id="header"
      color="light"
      light
      expand="md"
      // Conditional styling below - for the articles pages only
      style={{ position: scrollableLayout ? 'relative' : 'sticky' }}
    >
      <div className="container">
        <NavbarBrand
          tag={Link}
          to={path && path.match('trading') ? '/content/trading/forex' : '/'}
          {...{
            className: styles.navbarBrand,
          }}
        >
          WikiJob
        </NavbarBrand>
        <NavbarToggler onClick={() => updateOpen(!open)} />
        <Collapse isOpen={open} navbar>
          <Nav className="ml-auto" navbar>
            {data.menuLinks.edges
              .filter(({ node }) => path.match(node.urlMatch))
              .map(renderDropdownMenu)}

            <NavItem className={classNames('input-group', styles.searchField)}>
              <NavLink
                tag={Link}
                to={`/search?q=${search}`}
                {...{
                  disabled: search.length === 0,
                  className: 'search-button input-group-addon',
                }}
              >
                <Search size="20" color="white" />
              </NavLink>
              <input
                {...{
                  type: 'text',
                  className: classNames('form-control', styles.formControl),
                  placeholder: 'Search',
                  value: search,
                  onChange: (e) => updateSearch(e.target.value),
                  onKeyPress: onPressEnterSearch,
                }}
              />
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

const renderDropdownMenu = ({
  node: { title, menuItems, position, navbarLink },
}) => {
  if (menuItems.length > 0) {
    return (
      <WikiJobDropdownMenu key={position} title={title} items={menuItems} />
    )
  }
  return (
    <Nav key={navbarLink} className="ml-auto" navbar>
      <NavItem>
        <NavLink className="mainMenu" tag={Link} to={navbarLink}>
          {title}
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default FirstMenu
