import React, { useState } from 'react'
import Posed from 'react-pose'
import { X } from 'react-feather'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import styles from './HelloBar.module.scss'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const HelloBarBlock = Posed.div({
  true: {
    opacity: 1,
    transform: 'scale(1, 1)',
    width: '100%',
  },
  false: {
    width: 0,
    height: 0,
    opacity: 0,
    transform: 'scale(1, 0)',
  },
})

export default ({ location }) => {
  const aptitudeTest = useSelector((state) => state.aptitudeTest)

  const [visible, setVisible] = useState(true)
  return (
    visible && (
      <HelloBarBlock
        role="alert"
        className={styles.helloBarBlockWrap}
        pose={visible}
      >
        <div className={styles.helloBarBlock}>
          {aptitudeTest.helloBarText}
          <a
            role="button"
            aria-pressed="true"
            href={decorateURLWithTID(
              aptitudeTest.helloBarUrl,
              location.pathname,
              'hellobar',
            )}
            className={classNames(
              'helloBarBtn',
              styles.helloBarBlockButtonActive,
            )}
            rel="sponsored"
            target="_blank"
          >
            {aptitudeTest.helloBarButtonText}
          </a>
        </div>
        <button
          {...{
            type: 'button',
            rel: 'nofollow',
            datadismiss: 'alert',
            arialabel: 'Close',
            className: styles.helloBarBlockButtonClose,
          }}
          onClick={() => setVisible(false)}
        >
          <X size={30} color="white" />
        </button>
      </HelloBarBlock>
    )
  )
}
