import React, { useState } from 'react'
import { Link } from 'gatsby'
import styles from 'src/components/FirstMenu/FirstMenu.module.scss'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import articleUrl from 'src/utils/articleUrl.js'

export default function WikiJobDropdownMenu(props) {
  const [open, setOpen] = useState(false)

  function formatYear(s) {
    let year = new Date().getUTCFullYear()
    return s.replace('{YEAR}', year)
  }

  return (
    <Dropdown
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      isOpen={open}
      toggle={() => setOpen(!open)}
      nav
      inNavbar
    >
      <DropdownToggle nav caret>
        {props.title}
      </DropdownToggle>
      <DropdownMenu className={styles.dropdownMenu} right>
        {props.items.map(item => {
          return (
            <DropdownItem
              tag={Link}
              key={item.id + item.url}
              to={item.url ? item.url : articleUrl(item)}
              className="mainMenu"
            >
              {item.title && formatYear(item.title)}
              {item.text}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}
