module.exports = (url, locationPathname, tid) => {
  // if (tid === 'linkly' || tid === 'hero' || tid === 'stickyheader') {
  if (!url.trim().startsWith('https://tests.wikijob.co.uk/')) {
    return url
  }
  // }

  const tid3suffix =
    locationPathname.includes('/content/') ? '&tid3=articles'
      : locationPathname.includes('/aptitude-test/') ? '&tid3=aptitude-tests'
        : ''

  const pathList = locationPathname.split('/').filter(item => item)
  const resultPath = []
  resultPath.unshift(pathList.pop())

  return `${url}?tid1=${resultPath
    .join('-')
    .substring(0, 25)}&tid2=${tid}${tid3suffix}${process.env.TID4}`
}
